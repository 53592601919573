module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.blackalsatian.co.za/graphql","debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"timeout":120000,"perPage":20,"requestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"verbose":true,"excludeFieldNames":["comments","blocksJSON","previewBlocks","previewBlocksJSON"],"develop":{"nodeUpdateInterval":1000,"hardCacheMediaFiles":true,"hardCacheData":false},"html":{"imageMaxWidth":1200,"fallbackImageMaxWidth":1000,"useGatsbyImage":true,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"excludeFieldNames":["contentNodes","seo","template","contentTypeName","enclosure","isContentNode","isTermNode","parentDatabaseId","parentId","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Black Alsatian","short_name":"Black Alsatian","description":"Handcrafted web applications and bespoke digital marketing solutions","start_url":"/","background_color":"#111827","theme_color":"#111827","display":"standalone","icon":"src/assets/images/blackalsatian-icon.png","icons":[{"src":"/favicon/icon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon/icon-64x64.png","sizes":"64x64","type":"image/png"},{"src":"/favicon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicon/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"/favicon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicon/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"/favicon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicon/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W3PSZZX","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer","routeChangeEventName":"route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
